<div class="p-fluid">
  <div class="p-field mb-6">
    <label class="mb-4" for="newPass">New Password</label>
    <input 
      #NewPass 
      pInputText 
      required 
      type="password" 
      class="w-full mt-4 placeholder:text-slate-400" 
      name="newPass" 
      placeholder="Enter a New Password" 
    />
  </div>
  <div class="p-field mb-6">
    <label class="mb-4" for="confirmPass">Confirm New Password</label>
    <input 
      #ConfirmPass 
      pInputText 
      required 
      type="password" 
      class="w-full mt-4 placeholder:text-slate-400" 
      name="confirmPass" 
      placeholder="Confirm Password" 
    />
  </div>
  <p-confirmPopup></p-confirmPopup>
  <button 
    pButton 
    label="Reset" 
    class=" w-1/3 h-10 mt-4" 
    (click)="confirm($event, NewPass.value, ConfirmPass.value)">
  </button>
</div>

<div class="w-[34vw]">
  <div class="mt-8">
    <div class="flex items-center mt-10">
      <p-avatar
        styleClass="float-left"
        size="xlarge"
        shape="circle"
        [icon]="user().isNone() ? 'pi pi-user' : undefined"
        [image]="!user().isNone() ? user().unwrap().photoURL ?? '' : ''"
      ></p-avatar>
      <div class="ml-6">
        <p class="mb-0 pt-1.5 font-medium">
          {{
            user().isNone()
              ? "loading..."
              : user().unwrap().displayName
          }}
        </p>
        <p class="mt-1 text-sm font-light text-gray-400">
          Change profile photo
        </p>
      </div>
    </div>
  </div>
  <div class="mt-10">
    <label class="mt-10 font-medium" for="email">Email</label>
    <input
      pInputText
      disabled
      id="email"
      class="mt-1.5 w-full"
      [value]="user().isNone() ? 'loading...' : user().unwrap().email"
    />
  </div>
  <div class="mt-6">
    <label class="mt-10 font-medium" for="org">Organization</label>
    <input
      pInputText
      disabled
      id="org"
      class="mt-1.5 w-full"
      [value]="
        org().isNone()
          ? 'Loading'
          : org().unwrap() == null
            ? 'None'
            : org().unwrap().name
      "
    />
  </div>
  <button
    pButton
    (click)="showResetDialog()"
    type="button"
    label="Reset Password"
    class="mt-6 w-1/3 bg-black text-white"
  ></button>
</div>

<div *ngIf="user == null" class="flex h-full w-full place-content-center">
  <p-progressSpinner ariaLabel="loading"></p-progressSpinner>
</div>

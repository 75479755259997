<div class="mb-4 flex justify-end">
  <button
    pButton
    icon="pi pi-pencil"
    label="Edit Configuration"
    (click)="showEditDialog()"
    class="mt-4 self-start"
  ></button>
</div>

<section class="mt-8 w-full">
  <div class="container mx-auto rounded-md bg-white p-4 shadow-md">
    <div class="mb-4 flex items-center justify-between">
      <h2 class="text-xl font-bold">Current Configuration</h2>
    </div>

    <div *ngIf="config() != undefined" class="space-y-4">
      <div class="field">
        <label class="block font-medium text-gray-700" for="llm">LLM</label>
        <input
          pInputText
          disabled
          id="llm"
          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          [value]="config()!.llm"
        />
      </div>
      <div class="field">
        <label class="block font-medium text-gray-700" for="prompt"
          >Prompt</label
        >
        <textarea
          pInputTextarea
          disabled
          id="prompt"
          rows="5"
          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          [value]="config()!.prompt"
        ></textarea>
      </div>
    </div>
  </div>
  <p-toast></p-toast>
</section>

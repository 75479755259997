<form [formGroup]="form" class="space-y-4">
  <div class="field">
    <label for="llm" class="block text-sm font-medium text-gray-700">LLM</label>
    <p-dropdown
      [options]="llms"
      formControlName="llm"
      optionLabel="label"
      [placeholder]="llm"
      class="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
    ></p-dropdown>
  </div>
  <div class="field">
    <label for="prompt" class="block text-sm font-medium text-gray-700"
      >Prompt</label
    >
    <textarea
      id="prompt"
      rows="5"
      formControlName="prompt"
      pInputTextarea
      class="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
    ></textarea>
  </div>
  <div class="field" *ngIf="this.auth.isAdmin">
    <label for="prompt" class="block text-sm font-medium text-gray-700"
      >Force organization users to use this configuration</label
    >
    <p-checkbox
      id="required"
      formControlName="required"
      [binary]="true"
      value=""
      class="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
    />
  </div>
  <div class="flex justify-end space-x-2">
    <button
      pButton
      type="button"
      icon="pi pi-check"
      label="Save"
      (click)="submit()"
      class="rounded-md px-4 py-2 text-white shadow-sm"
    ></button>
  </div>
</form>

<section class="mt-8 w-full">
  <div class="container mx-auto rounded-md bg-white p-4 shadow-md">
    <div class="mb-4 flex items-center justify-between">
      <h2 class="text-xl font-bold">Recent Generations</h2>
    </div>
    <p-table class="w-full" [value]="paginatedGenRecords()">
      <ng-template pTemplate="header">
        <tr>
          <th>Generation Type</th>
          <th>Document</th>
          <th>Status</th>
          <th>Date</th>
          <th class="text-center">Download</th>
          <th class="text-center">Open</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-record>
        <tr>
          <td>
            <generation-type-pill [type]="record.type"></generation-type-pill>
          </td>
          <td>{{ record.documentName }}</td>
          <td>
            <generations-status-pill
              [status]="record.status"
            ></generations-status-pill>
          </td>
          <td *ngIf="record.timestamp">
            {{ record.timestamp | date: "short" }}
          </td>
          <td *ngIf="!record.timestamp">N/A</td>
          <td class="text-center">
            <button
              pButton
              icon="pi pi-download"
              (click)="download(record)"
              class="p-button-rounded m-auto"
              [disabled]="record.status !== 'complete'"
            ></button>
          </td>
          <td class="text-center">
            <button
              pButton
              icon="pi pi-file"
              (click)="open(record)"
              class="p-button-rounded"
              [disabled]="record.status !== 'complete'"
            ></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <p-toast></p-toast>
</section>

<section class="mt-8 w-full">
  <div class="container mx-auto rounded-md bg-white p-4 shadow-md">
    <div class="mb-4 flex items-center justify-between">
      <h2 class="text-xl font-bold">Recent Documents</h2>
    </div>

    <p-table class="w-full" [value]="paginatedDocRecords()">
      <ng-template pTemplate="header">
        <tr>
          <th>File</th>
          <th>Uploaded</th>
          <th></th>
          <th class="text-center">Chat with Document</th>
          <th class="text-center">Preview Document</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-doc>
        <tr>
          <td class="pb-4 pt-4 text-grey-600">{{ doc.filename }}</td>
          <td>{{ doc.timestamp | date: "short" }}</td>
          <td></td>
          <td class="text-center">
            <button
              pButton
              icon="pi pi-comments"
              class="p-button-rounded"
              (click)="chatWithDocument(doc)"
            ></button>
          </td>
          <td class="text-center">
            <button
              pButton
              icon="pi pi-eye"
              class="p-button-rounded"
              (click)="previewDocument(doc)"
            ></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <p-toast></p-toast>
</section>

<div class="flex h-screen w-screen flex-col items-center justify-center gap-10">
  <p>
    Current count with loading message:
    {{
      this.countService.count().isNone()
        ? "Fetching Count..."
        : this.countService.count().unwrap()
    }}
  </p>

  <p>
    Current count with initial value:
    {{ this.countService.countWithInitial() }}
  </p>

  <!-- A 'bind' is necessary to preserve context -->
  <app-button
    [text]="'Add to Count'"
    [callback]="this.countService.addToCount"
  />
</div>
